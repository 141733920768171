import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Form, Input, Button, DatePicker, Select } from 'antd'
import Upload from 'rc-upload'
import { ArrowRightOutlined, InfoCircleOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import NumberFormat from 'react-number-format'
import AddFboModal from "./AddFboModal"
import RenderCountryState from './RenderCountryState';
import _ from "lodash"
import { firestore, serverTimestamp, firestorage } from "../../../firebaseConfig"
import Loader from "react-loader-spinner"
import FlyActions from '../../../redux/common'
import { ApiManager } from "../../../service"
import { STRIPE_PAYMENT_COUNTRY } from '../../../constants';
import isMobile from '../../../utils/isMobile';

const requiredFields = ['name', 'email', 'phone', 'country',
'state', 'address', 'fixedBasedOperations']

function AccountCompanyInfo(props) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const {history, currentUser} = props
    const [uploadedFiles, setUploadedFiles] = useState({files: [], uploading: false})
    const [fileToUpload, setFileToUpload] = useState({});
    const [additionalFBOs, setAdditionalFBOs] = useState(1)
    const [loading, setLoading] = useState(false)
    const [usedIslands, setUsedIslands] = useState({})
    
    window.mobileCheck = isMobile;

    useEffect(() => {
        if (currentUser) {
            setUploadedFiles({
                files: (!currentUser.profile.businessLicense || typeof (currentUser.profile.businessLicense) === 'string')
                    ? []
                    : currentUser.profile.businessLicense,
                uploading: false
            })
            setAdditionalFBOs((currentUser?.profile?.fixedBasedOperations && Object.keys(currentUser.profile.fixedBasedOperations)?.length) || 1)
            setUsedIslands(currentUser.profile.fixedBasedOperations || {})
        }
    }, [currentUser])

    const onSubmitHandler = (values) => {
        addCompanyInfo(values)
    }

    const addCompanyInfo = async (values) => {
        setLoading(true)
        // get user info from users collection and check if cid exists (added company info already)
        const uSnapshot = await firestore.collection('users').doc(currentUser.uid).get()
        if (uSnapshot.exists) { // user exists
            const userData = uSnapshot.data()
            const cid = userData.cid
            let cData = Object.assign({}, values)
            cData.uid = currentUser.uid
            cData.createdAt = serverTimestamp
            // replace undefinded values with '' to save data into firestore
            Object.keys(values).forEach(key => {
                if (cData[key] === undefined) cData[key] = ''
            })
            if (cData.licenseExpirationDate && cData.licenseExpirationDate !== '') {
                cData.licenseExpirationDate = cData.licenseExpirationDate.format('MM/DD/YYYY')
            }
            cData.businessLicense = uploadedFiles.files || []
            if (cData.businessLicense.length === 0) cData.licenseExpirationDate = ''
            if (currentUser.profile.businessLicense || currentUser.profile.licenseExpirationDate) {
                const sSnapshot = await firestore.collection('schedules')
                    .where('uid', '==', currentUser.uid)
                    .where('licenseType', '==', 'businessLicense').get();
                if (!sSnapshot.empty) {
                    sSnapshot.forEach(doc => {
                        firestore.collection('schedules').doc(doc.id).delete();
                    })
                }
            }
            if (cData.businessLicense.length !== 0 && cData.licenseExpirationDate) {
                ApiManager.scheduleLicenseExpiration({ uid: currentUser.uid, type: 'businessLicense', date: cData.licenseExpirationDate });
            }
            cData.accountCompanyInfoFilled = true
            cData.fleetModalSeen = true
            // company info not exists, add new company document to companies collection and add company doc id to user doc.
            await firestore.collection('users').doc(currentUser.uid).update(cData)
                .then(() => {
                    uploadedFiles.files.length !== 0 && utils.pushDataLayer({ event: 'uploaded_document' })
                    ApiManager.sendSlackAlert({
                        text: `🔔 company added \nBusiness name: ${values.companyName} \n# of planes: ${values?.aircraftNumber || '-'} \n# of pilots: ${values?.pilotsNumber || '-'}`
                    })
                })
            utils.openNotification('Saved the company information successfully!', true)
            dispatch(FlyActions.onLoadUser(currentUser))

            ApiManager.sendEmail({
                type: "businessRequest",
                id: currentUser.uid,
                uid: currentUser.uid,
                collection: "users",
                verified: "pending",
                reason: ""
            })
        } else {
            utils.openNotification('User information does not exist', false)
        }
        setLoading(false)
        const urlParams = new URLSearchParams(history.location.search)
        urlParams.set('pt', 'flights')
        history.push(`${history.location.pathname}?${urlParams.toString()}`)
    }

    useEffect(() => {
        if (Object.keys(fileToUpload).length !== 0) {
            setUploadedFiles({
                ...uploadedFiles,
                files: [...uploadedFiles.files, fileToUpload],
                uploading: false
            })
        }
    }, [fileToUpload]);

    const customUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setUploadedFiles({...uploadedFiles, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`companies/${currentUser.uid}/business-license-${utils.generateRandomKeys()}.${file.type === 'application/pdf' ? 'pdf' : 'png'}`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                setFileToUpload({ path: filePath, name: file.name })
            } catch(e) {
                setUploadedFiles({...uploadedFiles, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }

    const onDeleteFBO = (idx) => {
        const FBOdata = form.getFieldValue('fixedBasedOperations');
        delete FBOdata[idx];
        const newFboData = {};
        Object.values(FBOdata).map((value, index) => newFboData[index] = value);
        form.setFieldsValue({ 'fixedBasedOperations': newFboData });
        setAdditionalFBOs(additionalFBOs - 1);
    }

    const choosedCountry = form.getFieldValue('country');

    return (
        <div className="content">
            <div className="fly-form fly-form-mob">
                <Form
                    className=""
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                    initialValues={currentUser.profile}
                >
                    <div className="form-content">
                        <div className="form-header">
                            <h2>Company Information</h2>
                            <p className="fly-info mb-4">Flyers can send departure requests from your areas of operation, enter the email below to review & accept these requests. For verification please provide your active business license for approval.</p>
                        </div>
                        <div className="form-body">
                            <Row className="d-flex">
                                <Col xl={12} xs={24} className="pr-2">
                                    <Form.Item label="Company Name" name='companyName'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Company Name', true) }]
                                        }>
                                        <Input type="text" className="fly-input" placeholder="Add your registered business name" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                    </Form.Item>
                                </Col>
                                <Col xl={12} xs={24} className="pl-2">
                                    <Form.Item label="Company Email" name='companyEmail'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true)
                                        }]}>
                                        <Input type="email" className="fly-input" placeholder="Email" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/>}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col xl={12} xs={24} className="pr-2">
                                    <Form.Item label="Company Phone Number" name='phone'
                                        rules={[{ required: true, message: "This field is required"}]}>
                                        <NumberFormat className="fly-input" format="(###) ###-####" mask="_" placeholder="(000) 000-0000" />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} xs={24}  className="pl-2">
                                    <Form.Item label="Country" name='country' rules={[{ required: true, message: 'This field is required' }]}>
                                        <Select
                                            placeholder="Pick fom the list"
                                            className="fly-select"
                                            showSearch={true}
                                            options={utils.resources.defaultCountries}
                                            allowClear
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col xl={12} xs={24} className="pr-2">
                                    <Form.Item
                                        label={choosedCountry === STRIPE_PAYMENT_COUNTRY ? 'State' : 'Island'}
                                        name='state'
                                        rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                        <Select
                                            placeholder="Pick fom the list"
                                            className="fly-select"
                                            showSearch={true}
                                            options={choosedCountry === STRIPE_PAYMENT_COUNTRY
                                                ? utils.resources.usAllStates
                                                : utils.resources.bahamasAllIslands
                                            }
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} xs={24}  className="pl-2">
                                    <Form.Item label="Street address" name='address'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateAddress(rule, value, callback, 'Street address', true)
                                        }]}>
                                        <Input type="text" className="fly-input" placeholder="Add street" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/>}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col span={24}>
                                    <Form.Item label="Description" name='description'>
                                        <Input.TextArea rows="4" className="fly-input" placeholder="Start typing..." />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="Company Business License">
                                        {uploadedFiles.files.length !== 0 && (
                                            <div className="ant-upload-select uploaded">
                                                <CheckOutlined style={{ color: '#ffffff'}}/>
                                                <p>Uploaded!</p>
                                                <EditOutlined
                                                    className="p-right"
                                                    onClick={() => {
                                                        setUploadedFiles({...uploadedFiles, files: []});
                                                        form.setFieldValue('licenseExpirationDate', '');
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {uploadedFiles.uploading && (
                                            <div className="ant-upload-select uploading">
                                                <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                            </div>
                                        )}
                                        <div style={{
                                            position: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 'initial' : 'absolute',
                                            zIndex: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 1 : -1,
                                            opacity: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 1 : 0,
                                            pointerEvents: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 'initial' : 'none',
                                            touchAction: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 'initial' : 'none'
                                        }}>
                                            <Upload
                                                className="ant-upload ant-upload-select"
                                                customRequest={customUploadHandler}
                                                showUploadList={false}
                                                multiple={true}
                                                accept=".png,.jpg,.jpeg,.pdf"
                                            >
                                                <p style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                                                    {window?.mobileCheck()
                                                        ? 'Add your business license here'
                                                        : 'Drag-n-drop your business license here or browse from your computer'}
                                                </p>
                                            </Upload>
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col xl={8} xs={24} className="pr-2">
                                    <Form.Item
                                        label="License expiration date"
                                        name='licenseExpirationDate'
                                        rules={[{ required: uploadedFiles.files.length !== 0, message: 'This field is required' }]}
                                    >
                                        <DatePicker format="MM/DD/YYYY" placeholder="MM/DD/YYYY" className="fly-date-picker" defaultPickerValue={utils.getDefaultExpirationDate} disabledDate={utils.disabledDate} showToday={false}/>
                                    </Form.Item>
                                </Col>
                                <Col xl={8} xs={24} className="pr-2 pl-2">
                                    <Form.Item label="Number of pilots" name='pilotsNumber'>
                                        <Select
                                            placeholder="Pick one"
                                            className="fly-select"
                                            options={utils.resources.availableNumbers}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={8} xs={24} className="pl-2">
                                    <Form.Item label="Number of aircraft" name='aircraftNumber'>
                                        <Select
                                            placeholder="Pick one"
                                            className="fly-select"
                                            options={utils.resources.availableNumbers}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {_.times(additionalFBOs, (idx) => {
                                return (
                                    <RenderCountryState
                                        key={idx}
                                        idx={idx}
                                        fbo={currentUser && currentUser.profile.fixedBasedOperations}
                                        onDeleteFBO={onDeleteFBO}
                                        additionalFBOs={additionalFBOs}
                                        usedIslands={usedIslands}
                                        setUsedIslands={setUsedIslands}
                                        form={form}
                                    />
                                )
                            })}
                            <AddFboModal
                                {...props}
                                additionalFBOs={additionalFBOs}
                                setAdditionalFBOs={setAdditionalFBOs}
                                mainForm={form}
                                usedIslands={usedIslands}
                                setUsedIslands={setUsedIslands}
                                fixedCountry={choosedCountry}
                            />
                        </div>
                    </div>
                    <Form.Item shouldUpdate className="mt-5 t-right">
                        {() => (
                            <Button type="primary" htmlType="submit" className="fly-btn"
                                loading={loading}
                                disabled={
                                    currentUser.profile
                                        ? form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                                        : form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched(requiredFields, true)
                                }
                            >NEXT<ArrowRightOutlined /></Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AccountCompanyInfo
